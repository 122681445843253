.App {
  text-align: center;
}

body{
  max-width: 2000px !important;
  margin-top: 20px !important;
  padding-top: 56px; 
  color: black;
  background-color: var(--background) !important;
}
a{
  cursor: pointer;
  color: var(--colour-three) !important;
}
.col div{
  padding: 20px 10px !important;
}


:root{
  --accent-dark: #102542; /* (Oxford blue) */
  --accent-dark-light: #204983; /* (Polynesian blue) */
  --accent-light: #FFCB77; /* Sunset */
  --accent-urgent: #A02C3C; /* Amaranth purple */
  --accent-urgent-light: #CF4F60; /* Indian Red */ /* vier höher */
  /* --colour-one: #50AA92; */ /* Zomp */
  /* --colour-two: #309488; */ /* Dark Cyan */
  --colour-two: #3AB09E; /* Keppel */
  --colour-three: #107E7D; /* Teal */
  --colour-four: #105260; /* Midnight Green */
  --background-darker:  #dfeedf; /* #cbe0cb; */ /* #f2f8f2; */
  --background: #fcfffc ; /* #F1F9F7 Mint Cream */
  --borderR: 10px; 
}

.center-normal{
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.no-flex{
  flex: none !important ;
}
.fit{
  width: fit-content !important;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  text-align: center  ;
}
.margin-auto{
  margin: auto !important;
}
.left{
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  align-items: left;
  float: left;
}
.no-margin{
  margin: 0 !important;
}
.min{
  width: min-content !important;
}
/* * * * * * * */
/* *  COLORS * */
/* * * * * * * */

.c-al{
  color: var(--accent-light);
}

/* IMAGES */


/* * * * * * * */
/* Backgrounds */
/* * * * * * * */

.bg-ad{
  background-color: var(--accent-dark);
}
.bg-gr{
  background-color: var(--colour-two);
}
.bg-c3{
  background-color: var(--colour-three);
}
.bg-al{
  background-color: var(--accent-light);
}
.bg-lr{
  background-color: var(--accent-urgent-light);
}
.bg-gray{
  background-color: #e4e8e4 ;
}

.bg-darker{
  background-color: var(--background-darker);
}


.heading-highlight{
  padding: 5px;
  border-radius: 5px;
}

/* * * * * * * */
/* *  NAVBAR * */
/* * * * * * * */
.nav-link{
  color: var(--accent-dark) !important; 
}
.nav-link:hover {
  color: var(--background) !important;
  background-color: var(--accent-dark) !important;
  border-radius: 7px;
}
.navbar{
  backdrop-filter: blur(5px);
  transition: visibility 0s, 0.6s, opacity 0.6s linear, transform 1s;
  height: 50px;
}
  nav.navbar {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;

}
.dropdown-item{
  color: var(--accent-dark) !important;
}
.dropdown-item:hover{
  color: var(--background) !important;
  background-color: var(--accent-dark) !important;
}
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  background-color: var(--background);
  border-color: var(--accent-dark);
  border-radius: 7px;
}
.navbar-brand{
  color: var(--accent-dark) !important;
}
.dropdown-menu{
  margin-top: 0 !important;
}


/* * * * * * * */
/* *  BOXES  * */
/* * * * * * * */

.text-box{
  height: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  background-color: var(--background-darker);
}

.welcome-box{
  border-style: solid;
  border-width: 5px;
  border-color: var(--colour-four);
  text-align: center;
  padding: 2vw;
  border-radius: 7px;
  max-width: 70%;
  margin-bottom: 4vw;
  margin-top: 4vw;
}

.guide-box{
  text-align: left;
  padding: 1vw;
  border-radius: 7px;
  max-width: 80%;
  margin-bottom: 1vw;
  margin-top: 1vw;
}

.under-construction-box{
  margin-top: 100px;
  text-align: center;
  border: 5px solid var(--accent-urgent); 
  border-radius: 10px;
  padding: 30px;

}


/* * * * * * * */
/* MEDIA RULES */
/* * * * * * * */
/*For tablet or bigger*/
@media screen and (min-width: 992px) {
  /* navbar opens on hover*/ 
  .dropdown:hover .dropdown-menu {
      display: block;
  }
}


/* * * * * * * */
/* * CONTENT * */
/* * * * * * * */
.center-content{
  align-items: center;
  display: flex;
}

/* * * * * * * */
/* * Borders * */
/* * * * * * * */

p.dotted {border-style: dotted;}
p.dashed {border-style: dashed;}
p.solid {border-style: solid;}
p.double {border-style: double;}
p.groove {border-style: groove;}
p.ridge {border-style: ridge;}
p.inset {border-style: inset;}
p.outset {border-style: outset;}
p.none {border-style: none;}
p.hidden {border-style: hidden;}
p.mix {border-style: dotted dashed solid double;}

/* * * * * * * */
/*Social Media */
/* * * * * * * */

.fa {
  padding: 1vw;
  font-size: 30px;
  max-width: 5vw !important;
  max-height: 5vw !important;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}

.fa:hover {
    opacity: 0.7;
}
.fa-instagram {
  background: var(--accent-dark-light);
  color: white;
}
.fa-artists{
  margin-left: 1rem !important;
  margin-top: 1rem !important;
  box-shadow: 2px 0 2px 2px rgba(0,0,0,0.5);
  width: fit-content !important;
 padding: 0.7rem;
 background-color: var(--accent-dark-light) !important;
 color: white !important;
 font-size: large;
}

.fa-etsy{
  background-color: var(--accent-dark-light);
  color: white;
}
.fa-reddit {
  background: var(--accent-urgent-light);
  color: white;
} 
.fa-flickr {
  background: #f40083;
  color: white;
}
.fa-pinterest {
  background: #cb2027;
  color: white;
}
.fa-youtube {
  background: #bb0000;
  color: white;
}
.fa-twitter {
  background: #55ACEE;
  color: white;
}
.fa-envelope{
  background: var(--accent-light);
  color: white;
}
.fa-github{
  background-color: var(--accent-dark);
  color: white;
}


.show {
  display: block !important;
}
.noshow{
  display: none;
}
.filterable {
  margin-bottom: 4px;
  display: none;
}



.fit{
  width: fit-content; 
}


.maxfull{
  max-width: 100%;
}
.max70{
  max-width: 70%;
  max-height: 70%;
}
.max60{
  max-width: 60%;
  max-height: 60%;
}
.max50{
  max-width: 50%;
  max-height: 50%;
}
.max40{
  max-width: 40%;
  max-height: 40%;
}



.header-container{
  padding-top: 60px;
  height: 750px;
  padding-bottom: 60px;
  background-color: var(--background);
}

.header-title{
  color: var(--colour-four);
  text-align: left;
  align-self: flex-start;
  padding: 0 30px;
  font-size: 130px;
  font-weight: 900;
  line-height: 130px;
}

.base{
  background-color: var(--colour-three);
}

.border-left{
  border-left: 5px solid var(--colour-four);  
  padding-left: 10px; 
  padding-top: 5px;
  padding-bottom: 5px;
}

.caption{
  padding-top: 5px;
}

h2, h3{
  padding-bottom: 20px;
}


.blockquote-wrapper {
  display: flex;
  height: fit-content;
}

/* Blockquote main style */
.blockquote {
   position: relative;
   font-style: sans-serif;
   max-width: 80%;
   margin: 40px auto !important;
   align-self: center;
}

/* Blockquote header */
.blockquote h2 {
   font-style: italic;
   position: relative; /* for pseudos */
   color: black;
   font-size: 2.8rem;
   font-weight: normal;
   line-height: 1;
   font-size: larger;
   margin: 0;
   border: solid 5px var(--colour-four) !important;
   border-radius:20px;
   padding: 25px;
}

/* Blockquote right double quotes */
.blockquote h2:after {
   content:"";
   position: absolute;
   border: 5px solid var(--colour-four);
   border-radius: 0 50px 0 0;
   width: 60px;
   height: 60px;
   bottom: -60px;
   left: 50px;
   border-bottom: none;
   border-left: none;
   z-index: 3; 
}

.blockquote h2:before {
   content:"";
   position: absolute;
   width: 80px;
   border: 5px solid var(--background);
   bottom: -5px;
   left: 50px;
   z-index: 2;
}

/* increase header size after 600px */
@media all and (min-width: 600px) {
   .blockquote h1 {
       font-size: 3rem;
       line-height: 1.2;
  }

}

/* Blockquote subheader */
.blockquote h4 {
   position: relative;
   color: black;
   font-size: 1.3rem;
   font-weight: 400;
   line-height: 1.2;
   font-size: medium;
   margin: 0;
   padding-top: 15px;
   z-index: 1;
   margin-left:150px;
   padding-left:12px;
}


.blockquote h4:first-letter {
 margin-left:-12px;
}

/* #ct{height:150px; width:370px;border:4px solid var(--accent-dark);margin: 100px auto;text-align:center;position:relative;color:#fff;padding:15px;}
span{
  background:var(--background);
  color:var(--accent-dark);
  padding:0 10px;
  font-size:20px;
  position:relative; 
  top:-28px;
} */
.corner{
  height:36px;
  width:36px;
  border-radius:50%;
  border:4px solid #fff;
  transform:rotate(-45deg);  
  position:absolute;
  background:var(--background);
}

#left_top{
  top:-16px;
  left:-16px;
  border-width: 4px;
  border-color:transparent transparent var(--accent-dark) transparent;
}
#right_top{
  top:-16px;
  right:-16px;
  border-width: 4px;
  border-color:transparent transparent transparent var(--accent-dark);
}
#left_bottom{
  bottom:-16px;
  left:-16px;
  border-width: 4px;
  border-color:transparent var(--accent-dark) transparent transparent ;
}
#right_bottom{
  bottom:-16px;
  right:-16px;
  border-width: 4px;
  border-color:var(--accent-dark) transparent transparent transparent;
}




/* Blockquote main style */
.blockquotex {
   position: relative;
   font-style: sans-serif;
   font-weight: 800;
   color: black;
   padding: 30px 0;
   width: 100%;
   max-width: 80%;
   z-index: 1;
   margin: 80px auto;
   align-self: center;
   border-top: solid 1px var(--accent-dark);
   border-bottom: solid 1px var(--accent-dark);
}

/* Blockquote header */
.blockquotex h1 {
   position: relative;
   color: black;
   font-size: 20px !important;
   font-weight: 800;
   line-height: 1;
   margin: 0;
}

/* Blockquote right double quotes */
.blockquotex:after {
   position: absolute;
   content: "”";
   color: var(--accent-light);
   font-size: 5rem;
   line-height: 0;
   bottom: -25px;
   right: 30px;
}

/* increase header size after 600px */
@media all and (min-width: 600px) {
   .blockquotex h1 {
       font-size: 60px;
  }

}

/* Blockquote subheader */
.blockquotex h4 {
   position: relative;
   color: #292a2b;
   font-size: 1rem;
   font-weight: normal;
   line-height: 1;
   margin: 0;
   padding-top: 20px;
   z-index: 1;
}

.figure-img{
  padding: 30px 10px;
  border-radius: 10px;
  margin: 10px 0 !important;
}

/* Embeds */
.insta-media-box{
  padding:16px;
}
.instagram-media{
  background:#FFF; 
  border:0; 
  border-radius:3px; 
  box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); 
  margin: 1px; 
  max-width:200px; 
  /* min-width:326px;  */
  padding:0; 
  height: 460px;
  width:99.375%; 
  width:-webkit-calc(100% - 2px); 
  width:calc(100% - 2px);
}
.insta-media-box a{
  background:#FFFFFF; 
  line-height:0; 
  padding:0 0; 
  text-align:center; 
  text-decoration:none; 
  width:100%;
}
.insta-a{
  display: flex; flex-direction: row; align-items: center;
}
.insta-b{
  background-color: #F4F4F4; border-radius: 50%; flex-grow: 0; height: 40px; margin-right: 14px; width: 40px;
}
.insta-c{
  display: flex; flex-direction: column; flex-grow: 1; justify-content: center;
}
.insta-d{
  background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; margin-bottom: 6px; width: 100px;
}
.insta-e{
  background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; width: 60px;
}
.insta-f{
  padding: 19% 0;
}
.insta-g{
  display:block; height:50px; margin:0 auto 12px; width:50px;
}
.insta-h{
  padding-top: 8px;
}
.insta-i{
  color:#3897f0; font-family:Arial,sans-serif; font-size:14px; font-style:normal; font-weight:550; line-height:18px;
}
.insta-j{
  padding: 12.5% 0;
}
.insta-k{
  display: flex; flex-direction: row; margin-bottom: 14px; align-items: center;
}
.insta-l{
  background-color: #F4F4F4; border-radius: 50%; height: 12.5px; width: 12.5px; transform: translateX(0px) translateY(7px);
}
.insta-m{
  background-color: #F4F4F4; height: 12.5px; transform: rotate(-45deg) translateX(3px) translateY(1px); width: 12.5px; flex-grow: 0; margin-right: 14px; margin-left: 2px;
}
.insta-n{
  background-color: #F4F4F4; border-radius: 50%; height: 12.5px; width: 12.5px; transform: translateX(9px) translateY(-18px);
}
.insta-o{
  margin-left: 8px;
}
.insta-p{
  background-color: #F4F4F4; border-radius: 50%; flex-grow: 0; height: 20px; width: 20px;
}
.insta-q{
  width: 0; height: 0; border-top: 2px solid transparent; border-left: 6px solid #f4f4f4; border-bottom: 2px solid transparent; transform: translateX(16px) translateY(-4px) rotate(30deg)
}
.insta-r{
  margin-left: auto;
}
.insta-s{
  width: 0px; border-top: 8px solid #F4F4F4; border-right: 8px solid transparent; transform: translateY(16px);
}
.insta-t{
  background-color: #F4F4F4; flex-grow: 0; height: 12px; width: 16px; transform: translateY(-4px);
}
.insta-u{
  width: 0; height: 0; border-top: 8px solid #F4F4F4; border-left: 8px solid transparent; transform: translateY(-4px) translateX(8px);
}
.insta-v{
  display: flex; flex-direction: column; flex-grow: 1; justify-content: center; margin-bottom: 24px;
}
.insta-w{
  background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; margin-bottom: 6px; width: 224px;
}
.insta-x{
  background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; width: 144px;
}
.insta-y{
  color:#c9c8cd; font-family:Arial,sans-serif; font-size:14px; line-height:17px; margin-bottom:0; margin-top:8px; overflow:hidden; padding:8px 0 7px; text-align:center; text-overflow:ellipsis; white-space:nowrap;
}
.insta-z{
  color:#c9c8cd; font-family:Arial,sans-serif; font-size:14px; font-style:normal; font-weight:normal; line-height:17px; text-decoration:none;
}
.test{
  background-color: white; border-radius: 3px; border: 1px solid rgb(219, 219, 219); box-shadow: none; display: block; margin: 0px 0px 12px; min-width: 326px; padding: 0px;
}
.embed-row{
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  align-items: center !important
}
.embed-row-reddit{
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  height: min-content ;
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  align-items: center !important
}
.embed-row .rsme-instagram-embed:nth-child(2){
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.embed-row .rsme-pinterest-embed:nth-child(2){
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.reddit{
  max-height: 50% !important;
}

.reddit iframe{
  height: 400px !important;
  width: 250px !important;
}

#embed-title, .line-clamp-3{
  display: none !important;
}

/* SLIDER */
.slider{
  margin-top: 5vh;
}

/* ARTIST PROFILE */
.artist-profile{
  padding: 20px;
  border-radius: var(--borderR);
}
.selling-tag{
  margin-left: 1rem !important;
  padding: 0.5rem;
  margin-top: 1rem !important;
  box-shadow: 2px 0 2px 2px rgba(0,0,0,0.5);
  width: fit-content !important;
}
.artist-social-div{
  margin: 0 !important;
  flex: none !important;
}
/* HEADERS */
.popart{ 
  font-size: 4rem;
	letter-spacing:0.1em;
  -webkit-text-fill-color: var(--background);
  -webkit-text-stroke-width: 0.3vw;
  -webkit-text-stroke-color: var(--colour-four);
  text-shadow: 
						0.4vw 0.4vw var(--colour-three),
						0.6vw 0.6vw var(--colour-two);
}

.urgent-popart{
  font-size: 4rem;
	letter-spacing:0.1em;
  -webkit-text-fill-color: var(--background);
  -webkit-text-stroke-width: 0.3vw;
  -webkit-text-stroke-color: var(--accent-urgent);
  text-shadow: 
						0.4vw 0.4vw var(--accent-urgent-light),
						0.6vw 0.6vw var(--accent-light);
}
.popart-wrapper{
  min-height: 1vw;
}

/* SPEECH BUBBLE */
.bubble {
  --r: 1em; /* the radius */
  --t: 1.5em; /* the size of the tail */

  max-width: 40rem;
  padding: 1em;
  border-inline: var(--t) solid #0000;
  border-radius: calc(var(--r) + var(--t)) / var(--r);
  mask: radial-gradient(100% 100% at var(--_p) 0, #0000 99%, #000 102%)
      var(--_p) 100% / var(--t) var(--t) no-repeat,
    linear-gradient(#000 0 0) padding-box;
    background-color: var(--accent-dark);
  color: #fff;
}
.bubble-left {
  --_p: 0;
  border-bottom-left-radius: 0 0;
  place-self: start;
}
.bubble-right {
  --_p: 100%;
  border-bottom-right-radius: 0 0;
  place-self: end;
}



img{
  max-width: 100%;
}



figure {
  display: grid;
  justify-content: center;
  border: var(--accent-dark) 2px solid;
}

figcaption {
  background-color: var(--accent-dark-light);
  color: white;
  align-self: center;
  text-align: center;
  padding: 5px;
}




.webcard {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  width: 40% !important;
  background-color: var(--background-darker);
  margin: 10px 50px; 
}