
.month {
    padding: 70px 25px;
    width: 100%;
    margin-top: 40px;
    background: var(--accent-dark-light);
    text-align: center;
    border-radius: 10px;
    color: white;
  }

.cal-entry{
  border-color: var(--accent-dark);
  border-radius: 10px;
  border-width: 2px;
  border-style: dotted; 
  margin-top: 20px;
}

.entry-body{
  padding: 2vw 4vw;
  
}
 
.entry-header{
  background-color: var(--accent-dark-light);
  padding: 15px;
  border-bottom-color: var(--accent-dark);
  border-bottom-width: 1px;
  color: white;
  border-bottom-style: solid; 
}
  
  .month ul {
    margin: 0;
    padding: 0;
  }
  
  .month ul li {
    color: var(--ourbeige) !important;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 3px;
    list-style: none;
  }
  
  
  .prev button, .next button{
    color: var(--ourbeige) !important;
    background-color: inherit;
  }
  .month .prev {
    float: left;
    padding-top: 10px;
  }
  
  .month .next {
    float: right;
    padding-top: 10px;
  }
  
  .weekdays {
    border-radius: 10px;
    margin: 0;
    padding: 10px 0;
    background-color: #ddd;
  }
  
  .weekdays li {
    display: inline-block;
    width: 13.6%;
    color: black;
    text-align: center;
  }
  
  .days {
    border-radius: 10px;
    padding: 10px 0;
    background: #eee;
    margin: 0;
  }
  
  .days li {
    list-style-type: none;
    display: inline-block;
    width: 13.6%;
    text-align: center;
    margin-bottom: 5px;
    font-size:12px;
    color: black;
  }
  
  .days li .active {
    background-color: var(--accent-dark-light) ;
    cursor: pointer;
    color: white; 
  }

  .days li button span {
    padding: 5px;
  }
  
  /* Add media queries for smaller screens */
  @media screen and (max-width:720px) {
    .weekdays li, .days li {width: 13.1%;}
  }
  
  @media screen and (max-width: 420px) {
    .weekdays li, .days li {width: 12.5%;}
    .days li .active {padding: 2px;}
  }
  
  @media screen and (max-width: 290px) {
    .weekdays li, .days li {width: 12.2%;}
  }